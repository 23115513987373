import React, {
  ElementRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import { getEmployees } from 'api/employees'
import { handlerError } from 'utils/handlerError'
import { parseDataToBenefits, parseDataToEmployees } from 'utils/parseData'
import { ButtonTypes } from 'constants/buttonTypes'
import * as ST from './styled'
import { Placeholders } from 'constants/placeholders'
import { createBenefitApplication } from 'api/benefitsApplications'
import { BenefitTypes } from 'types/model/benefit'
import {
  listBenefitOperationTypes,
  listBenefitOperationTypesForUser,
} from 'constants/benefitOperationTypesDescription'
import { listBenefitTypes } from 'constants/benefitTypesDescription'
import { BenefitType } from 'constants/benefitTypes'
import { BenefitOperationTypes } from 'constants/benefitOperationTypes'
import CalendarInput from 'components/ui/inputs/Calendar'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import debounce from 'lodash/debounce'
import { Autocomplete, Box, TextField, useMediaQuery } from '@mui/material'
import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'
import { BreakPoints } from 'constants/breakPoints'
import { UserTypes } from 'types/model/user'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { UserStatuses } from 'constants/userStatuses'
import { urlArrayNullable } from 'utils/validation/tests'
import { ErrorMessages } from 'constants/errorMessages'
import { useAuth } from 'hooks/useAuth'
import { DATE_FORMAT_DTO } from 'constants/Date'
import BaseButton from 'components/ui/buttons/BaseButton'
import { Plus } from '../CreateGrade/styled'

export interface Props {
  show: boolean
  onClose: SetState<boolean>
  onSuccess?: () => void
  userId?: number
  userData: UserTypes.Model[]
  benefitData: BenefitTypes.Model[]
  benefitWallet?: number
}

const CreateEmployeeEnum = {
  emptyString: '',
  firstIndexInArray: 0,
}

const selectsBenefitApplicationEnum = {
  targetUser: 'targetUser',
  commentary: 'commentary',
  value: 'value',
  benefit: 'benefit',
  benefitId: 'benefitId',
  cancellationDate: 'cancellationDate',
}

const operationType = 'operationType'

const enum CreateBenefitApplicationStrings {
  sizeInputBig = 'big',
  surname = 'surname',
  name = 'name',
  email = 'email',
  salary = 'salary',
  patronymic = 'patronymic',
  date = 'date',
  dateFormat = '##.##.####',
  department = 'department',
  post = 'post',
  grade = 'grade',
  none = 'none',
  file = 'file',
  hardwareType = 'hardwareType',
  cost = 'cost',
  responsibleUser = 'responsibleUser',
  serialNumber = 'serialNumber',
  type = 'type',
}

const errorText = 'Не заполнены обязательные поля'

type Form = {
  targetUserId: number | null
  targetUser: IItem<number> | null
  commentary: string
  value: number
  benefitId: number | null
  benefit: IItem<number> | null
  cancellationDate: string
  type: BenefitType
  typeData: IItem<BenefitType>
  operationTypeData?: IItem<BenefitOperationTypes>
  operationType: BenefitOperationTypes
  links: string[]
}

// TODO refactor; BaseInput
const CreateBenefitApplication = ({
  show,
  onClose,
  onSuccess,
  userData,
  benefitData,
  benefitWallet,
  userId,
}: Props) => {
  const modalRef = useRef<ElementRef<'div'>>(null)
  const isMobileDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_PORTRAIT})`
  )

  const {
    permissions: { admin: isAdmin, hr: isHR },
  } = useAuth()

  const [userList, setUserList] = useState<Array<IItem>>([])
  const [benefitList, setBenefitList] = useState<BenefitTypes.Model[]>([])
  const [filteredBenefitList, setFilteredBenefitList] = useState<
    Array<IItem<number>>
  >([])
  const [cancellationDate, setCancellationDate] = useState<Date>(
    new Date(Date.now())
  )
  const [selectedBenefit, setSelectedBenefit] = useState<IItem<number> | null>(
    null
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    handleChange,
    values,
    resetForm,
    handleSubmit,
    errors,
    isValid,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormik<Form>({
    enableReinitialize: false,
    validateOnMount: true,
    initialValues: {
      targetUserId: userId ?? 0,
      targetUser: { value: 0, item: '' },
      commentary: '',
      value: 0,
      benefitId: null,
      benefit: null,
      cancellationDate: moment().format(DATE_FORMAT_DTO),
      type: BenefitType.DEVICES,
      typeData: listBenefitTypes[1],
      operationTypeData: undefined,
      operationType: BenefitOperationTypes.refill,
      links: [],
    },
    onSubmit: () => {
      const newObj = {
        targetUserId: values.targetUserId!,
        commentary: values.commentary,
        value: +values.value,
        benefitId: selectedBenefit?.value!,
        cancellationDate: moment(cancellationDate).format(DATE_FORMAT_DTO),
        links: values.links,
      }
      setIsLoading(true)
      createBenefitApplication(newObj)
        .then(() => {
          handleClose()
          resetForm()
          onSuccess?.()
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    validationSchema: Yup.object().shape({
      value: Yup.number().required(RequiredFields.base),
      benefitId: Yup.number().nullable().required(RequiredFields.base),
      type: Yup.number().required(RequiredFields.base),
      operationType: Yup.number().required(RequiredFields.base),
      cancellationDate: Yup.string().required(RequiredFields.base),
      links: Yup.array().nullable(),
    }),
  })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal-root')

  const handleClose = (): void => {
    onClose(!show)
  }

  const removeLink = (index: number) => {
    setFieldValue(
      'links',
      values.links.filter((link, linkIndex) => index !== linkIndex)
    )
    setFieldTouched(`links[${index}]`, false)
  }

  const addLink = () => {
    setFieldValue('links', [...values.links, ''])
    setFieldTouched(`links[${values.links.length}]`, false)
  }

  const changeLink = (link: string, index: number) => {
    setFieldValue(
      'links',
      values.links.map((linkItem, linkIndex) => {
        if (index === linkIndex) {
          return link
        }
        return linkItem
      })
    )
    setFieldTouched(`links[${index}]`, true)
  }

  const handleInputChange = (value: string) => {
    getEmployees(1, { status: [UserStatuses.active] }, value)
      .then((data) => {
        setUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const linksIsRequired = useMemo(
    () => values?.operationType === BenefitOperationTypes.withdrawal,
    [values?.operationType]
  )

  const isFullValid = useMemo(() => {
    return (
      isValid &&
      (linksIsRequired ? values.links.length > 0 : true) &&
      urlArrayNullable(values.links)
    )
  }, [isValid, values.links.length, JSON.stringify(values.links)])

  useEffect(() => {
    setUserList(parseDataToEmployees(userData))
  }, [userData])

  useEffect(() => {
    setBenefitList(benefitData)
  }, [benefitData])

  useEffect(() => {
    setFilteredBenefitList(
      parseDataToBenefits(
        benefitList.filter(
          (benefit) => benefit.operationType === values.operationTypeData?.value
        )
      )
    )
  }, [benefitList])

  const SignupForm = () => {
    return (
      <>
        <ST.InputsBlock>
          {(isAdmin || isHR) && (
            <ST.SelectWrapper>
              <ST.Label>Сотрудник</ST.Label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                clearOnBlur={false}
                blurOnSelect
                noOptionsText={'Нет доступных вариантов'}
                options={userList}
                style={
                  isMobileDevice
                    ? {
                        display: 'flex',
                        alignItems: 'center',
                        color: '#000000',
                        backgroundColor: '#f8f8f8',
                        boxSizing: 'border-box',
                        borderRadius: '10px',
                        height: '48px',
                        fontWeight: '400',
                        fontSize: '16px',
                        width: '100%',
                        fontFamily: 'Golos, sans-serif',
                      }
                    : {
                        color: '#000000',
                        backgroundColor: '#f8f8f8',
                        padding: '0',
                        marginBottom: '16px',
                        borderRadius: '10px',
                        height: '20px',
                        fontWeight: '400',
                        fontSize: '16px',
                        width: '100%',
                        fontFamily: 'Golos, sans-serif',
                      }
                }
                sx={{
                  '& .MuiInputBase-root': {
                    padding: '0 !important',
                  },
                  '& .Mui-focused.MuiFormLabel-root.MuiInputLabel-root': {
                    opacity: '0',
                  },
                  '& .MuiFormLabel-filled': {
                    opacity: '0 !important',
                  },
                  '& .MuiFormLabel-filled + div > fieldset > legend': {
                    display: 'none !important',
                  },
                  '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: 'transparent',
                    },
                  '& .MuiFormLabel-root': {
                    top: '-8px',
                    color: '#606060',
                    opacity: '0.5',
                    fontFamily: 'Golos, sans-serif',
                  },
                  '& .MuiAutocomplete-input': {
                    ...(isMobileDevice
                      ? {
                          paddingLeft: '16px !important',
                          paddingRight: '16px !important',
                          fontFamily: 'Golos, sans-serif !important',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '140%',
                          color: 'black',
                        }
                      : {
                          paddingLeft: '10px !important',
                          fontFamily: 'Golos, sans-serif !important',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '140%',
                          color: 'black',
                        }),
                  },
                  '& fieldset': {
                    border: 'none !important',
                  },
                }}
                filterSelectedOptions
                value={values.targetUser}
                onInputChange={debounce(
                  (e: any) => handleInputChange(e?.target?.value),
                  1000
                )}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.value === value.value
                }
                filterOptions={(options: any) => options}
                getOptionLabel={(option: any) => option.item}
                onChange={(event, value: any) => {
                  if (value) {
                    setFieldValue('targetUserId', value?.value)
                    setFieldValue('targetUser', value)
                  } else {
                    setFieldValue('targetUserId', null)
                    setFieldValue('targetUser', null)
                  }
                }}
                renderOption={(props: any, option: any) => {
                  return (
                    <Box
                      component="li"
                      style={{ backgroundColor: '#f8f8f8', border: 'none' }}
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                      key={option.value}
                    >
                      {option.item}
                    </Box>
                  )
                }}
                renderInput={(params: any) => (
                  <TextField
                    style={{
                      backgroundColor: '#f8f8f8',
                      borderWidth: 0,
                      '::placeholder': {
                        opacity: '0.5',
                      },
                      borderRadius: '10px',
                    }}
                    {...params}
                    label="Выберите сотрудника"
                  />
                )}
              />
            </ST.SelectWrapper>
          )}
          <ST.SelectWrapper>
            <ST.Label>
              Тип бенефита<ST.Star>*</ST.Star>
            </ST.Label>
            <BaseSelect
              isSmallSelect={false}
              placeHolder={Placeholders.benefitOperationalType}
              listItems={
                isAdmin || isHR
                  ? listBenefitOperationTypes
                  : listBenefitOperationTypesForUser
              }
              name={operationType}
              value={values?.operationTypeData?.item || ''}
              typeSelect={operationType}
              onChange={(newValue) => {
                setFilteredBenefitList(
                  parseDataToBenefits(
                    benefitList.filter(
                      (benefit) => benefit.operationType === newValue?.value
                    )
                  )
                )
                setFieldValue(operationType, newValue?.value)
                setFieldValue(selectsBenefitApplicationEnum.benefit, {
                  item: '',
                  value: 0,
                })
                setSelectedBenefit(null)
                setFieldValue('benefitId', null)
              }}
              clickableElements={[modalRef.current]}
            />
          </ST.SelectWrapper>
          <ST.SelectWrapper>
            <ST.Label>
              Вид бенефита<ST.Star>*</ST.Star>
            </ST.Label>
            <BaseSelect
              isSmallSelect={false}
              placeHolder={Placeholders.benefitOperationalType}
              listItems={filteredBenefitList}
              name={selectsBenefitApplicationEnum.benefit}
              value={selectedBenefit?.item || ''}
              typeSelect={selectsBenefitApplicationEnum.benefit}
              onChange={(newValue) => {
                setSelectedBenefit(newValue)
                setFieldValue('benefitId', newValue?.value ?? null)
              }}
              clickableElements={[modalRef.current]}
              scrollTarget={modalRef.current}
            />
          </ST.SelectWrapper>
          <ST.InputWrapper>
            <ST.Label>
              Сумма<ST.Star>*</ST.Star>
            </ST.Label>
            <NumberFormat
              customInput={ST.Input}
              thousandsGroupStyle="thousand"
              thousandSeparator=" "
              decimalScale={2}
              placeholder={CreateEmployeeEnum.emptyString}
              sizeInput={CreateBenefitApplicationStrings.sizeInputBig}
              id={selectsBenefitApplicationEnum.value}
              name={selectsBenefitApplicationEnum.value}
              value={values.value === 0 ? '' : values.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value.replaceAll(' ', '')
                setFieldValue(selectsBenefitApplicationEnum.value, value)
              }}
            />
          </ST.InputWrapper>
          <ST.InputWrapper>
            <ST.Label>
              Дата списания (действие до, включительно)<ST.Star>*</ST.Star>
            </ST.Label>
            <CalendarInput
              setStartDate={setCancellationDate}
              startDate={cancellationDate}
              minDate={new Date(Date.now())}
            />
          </ST.InputWrapper>

          <ST.InputWrapper>
            <ST.Label>Ссылки{linksIsRequired && <ST.Star>*</ST.Star>}</ST.Label>
            {values.links.map((link, index) => {
              return (
                <ST.InputWrapperWithDelete key={`links-${index}`}>
                  <ST.Input
                    type="text"
                    placeholder={Placeholders.url}
                    name={`links-${index}`}
                    value={link}
                    onBlur={handleBlur(`links[${index}]`)}
                    sizeInput={CreateBenefitApplicationStrings.sizeInputBig}
                    onChange={(e) => {
                      changeLink(e.target.value, index)
                    }}
                  />
                  <ST.CloseWrapper>
                    <Close
                      onClick={() => {
                        removeLink(index)
                      }}
                    />
                  </ST.CloseWrapper>
                </ST.InputWrapperWithDelete>
              )
            })}
            {values.links.length > 0 &&
            Array.isArray(touched.links) &&
            touched.links.filter((link: boolean) => link).length > 0 &&
            !urlArrayNullable(values.links) ? (
              <ST.ErrorText style={{ marginBottom: '10px' }}>
                {ErrorMessages.incorrectURL}
              </ST.ErrorText>
            ) : (
              ''
            )}
            <ST.InputSmallWrapperButton>
              <ST.AddButton
                disabled={
                  values.links.filter((link) => (link ?? '').length === 0)
                    .length > 0
                }
                onClick={() => addLink()}
              >
                <Plus />
                Добавить ссылку
              </ST.AddButton>
            </ST.InputSmallWrapperButton>
          </ST.InputWrapper>

          <ST.InputWrapper>
            <ST.Label>Комментарий</ST.Label>
            <ST.InputTextArea
              placeholder={Placeholders.commentary}
              sizeInput={CreateBenefitApplicationStrings.sizeInputBig}
              id={selectsBenefitApplicationEnum.commentary}
              name={selectsBenefitApplicationEnum.commentary}
              value={values.commentary}
              onChange={handleChange}
            />
          </ST.InputWrapper>
        </ST.InputsBlock>
        <ST.OutputBlock>
          <ST.ErrorText>
            {errors.value || errors.cancellationDate || errors.targetUserId
              ? errorText
              : ''}
          </ST.ErrorText>
        </ST.OutputBlock>
        <ST.ButtonWrapper>
          <BaseButton
            text="Подать заявку"
            type={ButtonTypes.submit}
            disabled={!isFullValid || isLoading}
            onClick={checkForm}
          />
        </ST.ButtonWrapper>
      </>
    )
  }

  return (
    <>
      {show ? (
        <ST.ModalOverlay>
          <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
            {isLoading && (
              <ModalLoader height={modalRef.current?.scrollHeight} />
            )}

            <ST.ModalContent>
              <ST.Close onClick={handleClose}>
                <Close />
              </ST.Close>
              <ST.ModalTitle>Заявка на бенефит</ST.ModalTitle>
              {SignupForm()}
            </ST.ModalContent>
          </ST.Modal>
        </ST.ModalOverlay>
      ) : null}
    </>
  )
}

export default CreateBenefitApplication
