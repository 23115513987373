import { TimeSelectTypes as Types } from './types'
import { FC, useCallback, useMemo } from 'react'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import moment, { Moment } from 'moment'
import { TIME_FORMAT } from 'constants/Date'

const INITIAL_TIME = '00:00'

const TIME_OPTIONS: string[] = new Array(48).fill(null).map((_, i) =>
  moment(INITIAL_TIME, TIME_FORMAT)
    .add(i * 30, 'm')
    .format(TIME_FORMAT)
)

const TimeSelect: FC<Types.Props> = ({
  dateValue,
  style,
  dropdownStyle,
  ...props
}) => {
  const getDateFromProps = useCallback(
    (): Moment | null =>
      dateValue
        ? typeof dateValue === 'string' || dateValue instanceof Date
          ? moment(dateValue)
          : dateValue
        : null,
    [dateValue]
  )

  const listItems = useMemo<IItem<Moment>[]>(
    () =>
      TIME_OPTIONS.map((opt) => {
        const [h, m] = opt.split(':')

        const dateFromProps = getDateFromProps()

        const value = dateFromProps?.isValid()
          ? dateFromProps.set({
              hour: +h,
              minutes: +m,
              seconds: 0,
            })
          : moment(opt, TIME_FORMAT)

        return {
          value,
          item: opt,
        }
      }),
    [getDateFromProps]
  )

  return (
    <BaseSelect
      onChange={(value) => {
        if (value) {
          const date = getDateFromProps()

          props.onChange?.(
            date
              ? {
                  ...value,
                  value: date.set({
                    hour: value.value.get('hour'),
                    minutes: value.value.get('minutes'),
                    seconds: 0,
                  }),
                }
              : value
          )
        }
      }}
      variant="highlightedOptions"
      listItems={listItems}
      placeHolder={INITIAL_TIME}
      style={{
        minWidth: 'unset',
        maxWidth: 'max-content',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        ...style,
      }}
      dropdownStyle={{ maxWidth: 'fit-content', top: 70, ...dropdownStyle }}
      {...props}
    />
  )
}

export default TimeSelect
