import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as ST from './styled'
import ReviewCard from './ReviewCard'
import { useTitle } from 'hooks/useTitle'
import { PAGES } from 'constants/pages'
import SelectsBlock from './SelectsBlock'
import Body from 'components/ui/layout/Body'
import AddReview from 'components/dialogs/Reviews/AddReview'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from 'components/ui/Loader'
import { ErrorMessages } from 'constants/errorMessages'
import { ReviewApiTypes } from 'api/review/types'
import { useGetReviewByIdQuery, useGetReviewsQuery } from 'store/api/review'
import { useGetAllUsersQuery } from 'store/api/user'
import { useAuth } from 'hooks/useAuth'
import { useModalWindowState } from 'hooks/useModalWindowState'
import { useAppSelector } from 'store/store'
import { useSearchParams } from 'react-router-dom'
import EmptyResult from 'components/views/feedback/EmptyResult'
import { UserStatuses } from 'constants/userStatuses'
import { IValuesFilter } from 'types/employeeType'
import BaseButton from 'components/ui/buttons/BaseButton'
import checkRole from 'utils/profile/checkRole'
import { ROLES } from 'constants/roles'
import useFilters from 'hooks/useFilters'
import EditionReview from 'components/dialogs/Reviews/EditionReview'
import CloseReview from 'components/dialogs/Reviews/CloseReview'
import { ReviewStatuses } from 'types/model/review'
import { setToastError } from 'utils/handlerError'
import getIntegerSearchParam from 'utils/url/getIntegerSearchParam'

type FiltersType = ReviewApiTypes.IReviewsValuesFilter

const DEFAULT_PAGE = 1

const DEFAULT_FILTERS: FiltersType = {
  user: null,
  status: null,
  type: null,
}

export const USERS_FILTERS: IValuesFilter = {
  status: [UserStatuses.active],
}

const searchParamsToFilterValues = (params: URLSearchParams): FiltersType => {
  const res: FiltersType = {}

  params.forEach((value, key) => {
    if (value !== null && value !== undefined && key in DEFAULT_FILTERS)
      res[key as keyof FiltersType] = +value
  })

  return res
}

const Reviews = () => {
  useTitle(PAGES.reviews)
  const [searchParams, setSearchParams] = useSearchParams()

  const userInfo = useAppSelector((s) => s.userInfo)

  const { modalOpen: editModalOpen, toggleModal: toggleEditModal } =
    useModalWindowState()
  const { toggleModal: toggleCancelModal, modalOpen: cancelModalOpen } =
    useModalWindowState()
  const [clearInput, setClearInput] = useState<boolean>(false)
  const [page, setPage] = useState<number>(DEFAULT_PAGE)

  const parsedParams = useMemo<FiltersType>(
    () => searchParamsToFilterValues(searchParams),
    [searchParams]
  )

  const getFiltersFromParams = useCallback(
    (useDefault = false) =>
      Object.keys(parsedParams).length
        ? parsedParams
        : useDefault
        ? DEFAULT_FILTERS
        : {},
    [parsedParams]
  )

  const { filters, submit, clear, clearDisabled, submitDisabled, setFilters } =
    useFilters<FiltersType>(getFiltersFromParams(true))

  const { toggleModal, modalOpen } = useModalWindowState()

  const { roles, userId } = useAuth()

  const {
    data: reviewsData,
    refetch: refetchReviews,
    isLoading,
    isFetching,
  } = useGetReviewsQuery({ page, ...getFiltersFromParams() })

  const reviewToEditId = getIntegerSearchParam('edit')

  const { data: reviewToEdit } = useGetReviewByIdQuery(reviewToEditId!, {
    skip: !reviewToEditId,
  })

  const { data: usersData } = useGetAllUsersQuery({
    parameters: USERS_FILTERS,
  })

  const loadReviewsInfinite = useCallback(() => {
    if (page < (reviewsData?.pageCount ?? -1)) setPage((p) => p + 1)

    // page dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewsData?.pageCount])

  const updateReviews = useCallback(() => {
    refetchReviews()
  }, [refetchReviews])

  const deleteSearchParam = (key: string) => {
    if (searchParams.has(key)) {
      searchParams.delete(key)
      setSearchParams(searchParams)
    }
  }

  useEffect(() => {
    if (reviewToEdit) {
      if (reviewToEdit.status !== ReviewStatuses.cancel) {
        toggleEditModal()
      } else {
        setToastError('Ревью отменено. Редактирование невозможно')
      }
    }
  }, [reviewToEdit, toggleEditModal])

  return (
    <>
      <Body>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <ST.ApplicationsHead>
              <ST.ApplicationsTitle>Ревью</ST.ApplicationsTitle>
              {checkRole(roles, [
                ROLES.hr,
                ROLES.recruiter,
                ROLES.customer,
                ROLES.admin,
              ]) && (
                <BaseButton
                  typeButton="primary"
                  text="Назначить ревью"
                  onClick={toggleModal}
                  width={250}
                  height={48}
                />
              )}
            </ST.ApplicationsHead>
            <ST.FilterBlock>
              <ST.FilterList>
                {usersData?.data?.length && (
                  <SelectsBlock
                    userList={usersData.data}
                    setValueFilter={setFilters}
                    valuesFilter={filters}
                    clearInput={clearInput}
                  />
                )}
              </ST.FilterList>
              <ST.ButtonsBlock>
                <BaseButton
                  typeButton="primary"
                  text="Применить"
                  width={'160px'}
                  height={40}
                  disabled={submitDisabled}
                  onClick={() => {
                    setPage(DEFAULT_PAGE)

                    const newFiltersValue = Object.entries(filters).reduce(
                      (prev, [key, value]) =>
                        value !== null && value !== undefined
                          ? {
                              ...prev,
                              [key]: value,
                            }
                          : prev,
                      {}
                    )

                    submit(newFiltersValue)
                    setSearchParams(newFiltersValue)
                  }}
                />
                <BaseButton
                  typeButton="secondary"
                  text="Сбросить"
                  width={'140px'}
                  height={'40px'}
                  disabled={clearDisabled}
                  onClick={() => {
                    setClearInput(true)
                    setPage(DEFAULT_PAGE)
                    setFilters(DEFAULT_FILTERS)
                    setSearchParams({})
                    clear()

                    queueMicrotask(() => {
                      setClearInput(false)
                    })
                  }}
                />
              </ST.ButtonsBlock>
            </ST.FilterBlock>
            {isFetching && page === 1 ? (
              <Loader marginTop="40px" />
            ) : !!reviewsData?.data.length ? (
              <ST.ApplicationsBody>
                <ST.ApplicationsBlock>
                  <InfiniteScroll
                    dataLength={reviewsData.data.length}
                    next={loadReviewsInfinite}
                    hasMore={page < reviewsData.pageCount}
                    loader={<Loader marginTop="40px" />}
                    endMessage={ErrorMessages.endLoading}
                    style={{
                      height: '100%',
                      overflow: 'initial',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px',
                    }}
                  >
                    {!!usersData?.data?.length &&
                      reviewsData.data.map((review) => (
                        <ReviewCard
                          key={review.id}
                          review={review}
                          updateReviewList={updateReviews}
                          usersList={usersData.data}
                          userInformation={review.user}
                        />
                      ))}
                  </InfiniteScroll>
                </ST.ApplicationsBlock>
              </ST.ApplicationsBody>
            ) : (
              <EmptyResult
                title="Ревью не найдены"
                description="Попробуйте изменить критерии поиска"
              />
            )}
          </>
        )}
      </Body>

      {!!userId && reviewToEdit && !isLoading && (
        <>
          <EditionReview
            showEditModal={toggleCancelModal}
            show={editModalOpen}
            onClose={() => {
              toggleEditModal()
              deleteSearchParam('edit')
            }}
            userId={userId}
            review={reviewToEdit}
            userList={usersData?.data ?? []}
            updateReviews={updateReviews}
          />
          <CloseReview
            review={reviewToEdit}
            onClose={toggleCancelModal}
            show={cancelModalOpen}
            updateReviews={updateReviews}
            onSubmit={() => {
              toggleEditModal()
              deleteSearchParam('edit')
            }}
            userId={userId}
          />
        </>
      )}

      {!!usersData?.data?.length && userInfo?.id && modalOpen && (
        <AddReview
          show={modalOpen}
          onClose={toggleModal}
          showEndModal={updateReviews}
          userData={usersData.data}
          userInformation={userInfo}
          forceUpdate={updateReviews}
        />
      )}
    </>
  )
}

export default Reviews
